import React from "react"

import { PageLayout } from "@components/layouts"
import SEO from "@components/seo"

import SingleColumnBlock from "@components/singleColumnBlock"

import ActiveItemGallery from "@components/ActiveItemGallery"

import { VideoPlayer } from "@components/video"

import {
  ScrollToButton,
} from "@components/buttons"

import {
  GuitarInquiry,
} from "@components/Forms"

const galleryItems = [
  {
    key: 1,
    title: "T-style Relic Custom Guitar",
    images: ["/images/guitar006-front.jpg"],
  },
  {
    key: 2,
    title: "Body Front",
    images: ["/images/guitar006-body-front.jpg"],
  },
  {
    key: 3,
    title: "Body Back",
    images: ["/images/guitar006-body-back.jpg"],
  },
  {
    key: 4,
    title: "Back",
    images: ["/images/guitar006-back.jpg"],
  },
  {
    key: 5,
    title: "Fabric Top",
    images: ["/images/guitar006-fabric-top.jpg"],
  },
  {
    key: 6,
    title: "New KEG Headstock",
    images: ["/images/guitar006-headstock.jpg"],
  },
  {
    key: 7,
    title: "Neck Back",
    images: ["/images/guitar006-neck-back.jpg"],
  },
  {
    key: 8,
    title: "Side",
    images: ["/images/guitar006-side.jpg"],
  },
  {
    key: 9,
    title: "Headstock Signiture",
    images: ["/images/guitar006-signiture.jpg"],
  },
  {
    key: 10,
    title: "Builder Photo",
    images: ["/images/guitar006-randy-king.jpg"],
  },
]

const Guitar001 = () => {
  return (
    <PageLayout>
      <SEO
        title="T-Style Relic Custom Guitar | P90 Pickups | Fabric Top"
        ogDescription="This T-style relic custom guitar features a light cyprus body, poplar top with reclic fabric finish, and dual P-90 pickups that sing. Let's build your dream guitar!"
      />
      <SingleColumnBlock
        textPadded
        text={
          <div className="content">
            <h1>T-Style Relic Custom Guitar</h1>
            <p className="detail">
              <b>Guitar:</b> T-Style Prototype #2
            </p>
            <p className="detail">
              <b>Finish:</b> Relic Fabric Top on Cyprus body with maple neck
            </p>
            <p className="detail">
              <b>Details:</b> T-Style body with dual P90 Bootstrap pickups
            </p>
            <ScrollToButton
              id="video"
              content="Watch Video"
              style={{ marginBottom: "20px" }}
            />
          </div>
        }
      />
      <ActiveItemGallery
        items={galleryItems}
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h2>This Guitar's Story</h2>
            <p>
              I don't always like relic'ed guitars, but when the wood and fabric call for it, it can be cool. This is a soft wood Cyprus body, so it's going to get dinged up naturally. So I put a little age and wear on it. The Bootstrap P90 pickups on this sucker sound fantastic. This is also the very first guitar to feature the new King Electric Guitars headstock. This is a T-style body, but since this is not my Taurus design, it gets the label of Prototype. The neck has a medium thick vintage feel to it, but tapers nicely near the neck pocket. Very comfortable to play.
            </p>
            <h2>Specifications</h2>
            <ul>
              <li>Maple neck & fretboard</li>
              <li>25.5-inch Scale</li>
              <li>12-inch Fretboard Radius</li>
              <li>Rolled Fretboard Edges</li>
              <li>Medium Cryowire Frets</li>
              <li>Dual Action Truss Rod</li>
              <li>Bolt on neck with Inserts and Stainless Steel Machine Screws</li>
              <li>Wenge Dot Inlays</li>
              <li>Bone Nut</li>
              <li>Gotoh Tuners</li>
              <li>Cypress Body</li>
              <li>Poplar Top</li>
              <li>Worn Fabric Top Finish</li>
              <li>Custom Mixed Milk Paint on Body</li>
              <li>Aged Binding, Hardware and Covers</li>
              <li>Amber Urethane Topcoat</li>
              <li>Wilkinson Bridge with Compensated Brass Saddles</li>
              <li>Angled Switch Control Plate</li>
              <li>CTS 500k Volume and Tone Pots</li>
              <li>Switchcraft Switch and Outpout Jack</li>
              <li>.033uf Orange drop Cap</li>
              <li>Vintage Cloth Push Back Wire</li>
            </ul>
            <h2>Price and Availability</h2>
            <p>
              <b>$2100</b> discounted prototype price<br />
              currently available
            </p>
          </div>
        }
      />
      <div className="section textured" id="video">
          <VideoPlayer youtubeId='_K1A1uhIs9E' />
      </div>
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h4>Ask Me About This Guitar!</h4>
            <GuitarInquiry
              guitar='T-Style Green Flower Fabric Top Relic Prototype #2'
           />
          </div>
        }
      />
      
    </PageLayout>
  )
}

export default Guitar001